import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import ThemeContext from "../../context/ThemeContext"
import { graphql } from "gatsby"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import SEO from "../../components/seo"
import MainSearch from "../../components/main-search/main-search"
import localize from "../../components/localize"

const Content = ({ bonuses, locale }) => {
  return (
    <div>
      <MainSearch locale={locale} bonuses={bonuses} />
    </div>
  )
}

const TaxExempt = ({ location, data, pageContext }) => {
  let site = useContext(ThemeContext)
  let { locale, alternateLocaleSlugs } = pageContext
  let bonuses = site.bonuses
  bonuses = bonuses
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => x.isTaxExempt)

  const pageData = data.sanityGenericLandingPage

  return (
    <Layout breadcrumbNameOverride={pageData.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} title={pageData && pageData.seoTitle}
           alternateSlug={alternateLocaleSlugs}
           description={pageData && pageData.seoMeta} bonuses={bonuses}
      />
      <GenericLandingContentWrapper pageData={pageData} headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}>
        <Content bonuses={bonuses} locale={locale} />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(TaxExempt)
export const pageQuery = graphql`
  query TaxExemptLanding {
    sanitySilo (_id: {eq: "382bf7f6-ce31-436f-9100-fbad76571f52"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityGenericLandingPage(
      name: { eq: "Is Tax Exempt" }
    ) {
      ...GenericLanding
    }
  }
`
